<template>
  <div class="card-informations">
    <v-row>
      
      <v-col cols="12" md="3">
        <div class="card-user-informations text-center">
          <v-icon class="mb-3" size="45px" color="#2c3e50">
            mdi-account-circle
          </v-icon>
          <h4 class="mb-3">{{ user.fullName }}</h4>
          <v-btn color="#2980b9" small outlined depressed @click="alterarSenha">
            <v-icon size="14">mdi-pencil</v-icon>
            <span >Alterar senha</span>
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" md="9">
        <div class="card-user-informations-all">
          <v-row>

            <v-col cols="12" md="12" sm="12">
              <h3>Informações pessoais</h3>
            </v-col>
            
            <v-col cols="12" md="6" sm="6">
              <h4 class="h4-information">E-mail</h4>
              <span class="span-information">{{ user.email }}</span>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <h4 class="h4-information">CPF</h4>
              <span class="span-information">{{ user.cpf | VMask('###.###.###-##')}}</span>
            </v-col>
            
            <v-col cols="12" md="6" sm="6">
              <h4 class="h4-information">Superintendência</h4>
              <span class="span-information">{{ user.superintendence }}</span>
            </v-col>

          </v-row>
        </div>
      </v-col>

      <v-col cols="12" md="12" sm="12" v-if="exibir == true">
        <ChangePassword />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from '@/axios'
import ChangePassword from './ChangePassword-comp'

export default {
  data() {
    return {
      user: {
        fullName: "",
        email: "",
        cpf: "",
        superintendence: ""
      },
      exibir: false,
    }
  },
  components: {
    ChangePassword
  },
  
  created() {
    axios // BUSCA USUÁRIO LOGADO
      .get(`/auth/me`)
      .then((response) => {
        this.user.fullName = response.data.name
        this.user.email = response.data.email
        this.user.cpf = response.data.cpf
        this.user.superintendence = response.data.superintendence_id
      });
  },

  methods: {
    alterarSenha() {
      this.exibir = !this.exibir
    }
  }
};
</script>

<style>
  .card-user-informations{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    min-height: 220px;
    padding: 25px;
  }

  .card-user-informations > h4{
    font-size: 14px;
  }

  .card-user-informations-all{
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    min-height: 220px;
    padding: 25px;
  }

  .h4-information {
    font-family: 'Poppins';
    font-size: 14px;
  }

  .span-information {
    font-family: 'Poppins';
    font-size: 16px;
  }
</style>