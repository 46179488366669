<template>
  <div>
    <Navbar />
    <v-main class="main">
      <v-container fluid>
        <Header />
        <Informations />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Navbar from "../components/Navbar-comp.vue";
import Header from "../components/Header-comp.vue";
import Informations from "../components/conta/Informations-comp.vue";
export default {
  components: {
    Navbar,
    Header,
    Informations,
  },
  data: () => ({}),
};
</script>

<style>
</style>