<template>
  <v-navigation-drawer app class="sidebar" v-model="resource">
    <div class="box-sidebar-logo mb-5">
      <div class="box-text-logo">
        <h4 class="logo-text">BI-</h4>
        <h4 class="logo-text-ti">HGRS</h4>
        <h3 class="logo-text-separador">|</h3>
        <h3 class="logo-text-sesab">SESAB</h3>
      </div>
    </div>

    <div class="card-menu" width="300">
      <v-list>
        <h6 class="text-section-menu">Menu</h6>
        <v-list-item-group class="mb-2" v-model="selectedItem" color="primary">
          <router-link to="/paineis-bi" class="text-decoration">
            <v-list-item>
              <v-list-item-title>
                <v-icon>mdi-chart-donut</v-icon>
                <span>Painéis BI</span>
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
<!-- 
        <v-list-group class="mb-2">
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-web</v-icon>
              <span>Sistemas</span>
            </v-list-item-title>
          </template>

          <v-list-item-content>
            <router-link to="/sistemas" class="text-decoration">
              <span class="sublist-text"> Catálogo completo </span>
            </router-link>
          </v-list-item-content>

          <v-list-item-content>
            <router-link to="/sistemas/assistencias" class="text-decoration">
              <span class="sublist-text"> Assistenciais </span>
            </router-link>
          </v-list-item-content>

          <v-list-item-content>
            <router-link to="/sistemas/administrativos" class="text-decoration">
              <span class="sublist-text"> Administrativos </span>
            </router-link>
          </v-list-item-content>

        </v-list-group> -->

        <v-list-group class="mb-2">
          <template v-slot:activator>
            <v-list-item-title>
              <v-icon>mdi-cog-outline</v-icon>
              <span>Aplicação</span>
            </v-list-item-title>
          </template>

          <v-list-item-content>
            <router-link to="/aplicacao/usuarios" class="text-decoration">
              <span class="sublist-text"> Usuários </span>
            </router-link>
          </v-list-item-content>

          <v-list-item-content>
            <router-link to="/aplicacao/dashboards" class="text-decoration">
              <span class="sublist-text"> Dashboards </span>
            </router-link>
          </v-list-item-content>
<!-- 
          <v-list-item-content>
            <router-link to="/aplicacao/sistemas" class="text-decoration">
              <span class="sublist-text"> Sistemas </span>
            </router-link>
          </v-list-item-content> -->

        </v-list-group>
      </v-list>
    </div>
    

    <img class="logo-sesab" src="../assets/bi-hgrs-GE.svg" alt="" />
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: "",
    };
  },

  props: {
    resource: {
      type: Boolean,
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let routeName = this.$router.history.current.path;

      if (routeName == "/paineis-bi") {
        this.selectedItem = 0;
      }

      if (routeName == "/sistemas") {
        this.selectedItem = 1;
      }
    },
  },
};
</script>

<style>
/* Version 2.0 */

.sublist-text {
  font-size: 14px;
  color: #000000;
  margin-left: 30px;
}

.v-list-group__items {
  margin-top: 8px;
}

.v-list-item__content {
  padding: 8px 0!important;
}

.v-icon.v-icon {
  font-size: 19px!important;
}

.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0!important;
}

/*  */
.sidebar {
  background: transparent !important;
  padding: 20px;
}

.v-navigation-drawer__border {
  width: 0px !important;
}

.box-sidebar-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  background: #e55d87; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 211, 48) 0%,
    rgb(15, 162, 207) 100%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    160deg,
    rgb(3, 211, 38) 0%,
    rgb(15, 162, 207) 100%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;
  padding: 10px;
  height: 64px;
}

.logo-img {
  height: 45px;
  width: 175px;
}

.logo-sesab {
  height: auto;
  width: 200px;

  position: absolute;
  bottom: 25px;
}

.box-text-logo {
  display: flex;
  align-items: center;
}

.box-text-logo > h4 {
  font-size: 20px;
  color: #ffffff;
}

.box-text-logo > h3 {
  font-size: 16px;
  color: #ffffff;
}

.logo-text {
  font-family: "Baloo 2", cursive;
  font-weight: 400;
}

.logo-text-ti {
  font-family: "Baloo 2", cursive;
  font-weight: 800;
}

.logo-text-separador {
  font-family: "Baloo 2", cursive;
  font-weight: 400;
  margin: 0 5px;
}

.logo-text-sesab {
  font-family: "Baloo 2", cursive;
  font-weight: 400;
}

.text-system-name {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}

.card-menu {
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
}

.text-section-menu {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 5px;
}

.text-decoration {
  text-decoration: none;
}

/* .v-list-item {
  margin: 10px 0;
} */

.v-list-item__title {
  display: flex;
  align-items: center;
}

.v-list-item__title > .v-icon {
  color: #2c3e50 !important;
  margin-right: 10px;
}

.v-list-item__title > span {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #2c3e50 !important;
}

.v-list-item--active {
  border-radius: 8px !important;
  border: 1px solid #3498db !important;
}

/* Mobile */
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
  background: rgb(248, 248, 248) !important;
}
</style>
