<template>
  <div>
    <Sidebar :resource="optionSidebar" />

    <v-app-bar color="#FFFFFF" app class="navbar" elevation="0">
      <div class="app-itens">
        <v-btn
          @click="optionSidebarFunction"
          icon
          color="#000000"
          small
          elevation="0"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <div class="text-center">
          <v-menu min-width="250px">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar color="blue" size="36" v-bind="attrs" v-on="on">
                <span class="white--text text-h6">{{ user.initials }}</span>
              </v-avatar>
            </template>

            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar color="blue" size="46" class="mt-3 mb-3">
                    <span class="white--text text-h5">{{ user.initials }}</span>
                  </v-avatar>

                  <h5>{{ user.fullName }}</h5>
                  <p class="text-caption mt-1">
                    {{ user.email }}
                  </p>
                  <v-divider class="my-3"></v-divider>
                  <router-link to="/minha-conta" class="text-decoration-none">
                    <v-btn depressed rounded text> Minha Conta </v-btn>
                  </router-link>
                  <v-divider class="my-3"></v-divider>
                  <v-btn depressed rounded text @click="logout">
                    Desconectar
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import axios from '@/axios'
import Sidebar from "../components/Sidebar-comp.vue";

export default {
  data() {
    return {
      optionSidebar: false,
      user: {
        initials: "",
        fullName: "",
        email: "",
      },
    };
  },

  components: {
    Sidebar,
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      let dimensao = window.screen.width;
      if (dimensao > 768) {
        this.optionSidebar = true;
      } else {
        this.optionSidebar = false;
      }

      axios // BUSCA USUÁRIO LOGADO
      .get(`/auth/me`)
      .then((response) => {
        this.user.initials = response.data.name.substr(0, 1)
        this.user.fullName = response.data.name
        this.user.email = response.data.email
      });
    },

    optionSidebarFunction() {
      this.optionSidebar = !this.optionSidebar;
    },

    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
  },
};
</script>

<style>
.navbar {
  position: absolute !important;
  margin-top: 20px !important;
  margin: 20px;
  border-radius: 10px !important;
}

.app-itens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
</style>
