<template>
  <div class="header-information">
    <div class="box-information-page">
      <h3>{{ information }}</h3>
      <div class="indicator"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      information: "Minha conta",
    };
  },
};
</script>

<style>
  .header-information {
    margin-bottom: 20px;
  }

  .header-information > .box-information-page {
    display: flex;
    flex-direction: column;
  }

  .header-information > .box-information-page > h3 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 18px;
  }

  .header-information > .box-information-page > .indicator {
    height: 2px;
    width: 50px;
    border-radius: 5px;
    margin-right: 10px;
    background: -webkit-linear-gradient(160deg, rgb(3, 69, 211) 0%, rgb(15, 162, 207) 100%);
  }
</style>